@charset "UTF-8";

body {
  font-family: var(--INTER);
  font-weight: 400;
  font-style: normal;
  font-size: var(--standartLg);
  line-height: 24px;
  color: var(--black);
  margin: 0;
  padding: 0;
  
  box-sizing: border-box;
}

body #cleversite_clever_button {
  /* right: 5px !important;
  bottom: 5px !important;
  z-index: 1 !important;
  */
}

body .link-black {
  color: var(--black);
  text-decoration: none;
}

body .link-black:hover {
  color: var(--main);
}

body .scrolled-form {
  max-height: 66vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE 10+ */
  scrollbar-width: none;
  /* Firefox */
}

body .scrolled-form::-webkit-scrollbar {
  /* chrome based */
  width: 0px;
  /* ширина scrollbar'a */
  background: transparent;
  /* опционально */
}

body .registration-scrolled-form {
  max-height: 75vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE 10+ */
  scrollbar-width: none;
  /* Firefox */
}

body .registration-scrolled-form::-webkit-scrollbar {
  /* chrome based */
  width: 0px;
  /* ширина scrollbar'a */
  background: transparent;
  /* опционально */
}

body .scrolled-tab_panel {
  display:  flex;
  flex-direction: column;
  gap: 32px;
  max-height: 70vh;
  overflow-y: scroll;
}


@media screen and (max-height: 715px) {
  body .scrolled-tab_panel {
    max-height: 60vh;
  }
}

body .scrolled-acts_panel {
  max-height: 78vh;
  overflow-y: scroll;
}

@media screen and (max-height: 715px) {
  body .scrolled-acts_panel {
    max-height: 70vh;
  }
}

body .scrolled-table {
  max-height: 60vh;
  overflow-y: scroll;
}

body .scrolled-sidebar {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 24px;
  max-height: calc(100vh - 228px);
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE 10+ */
  scrollbar-width: none;
  /* Firefox */
}

body .scrolled-sidebar::-webkit-scrollbar {
  /* chrome based */
  width: 0;
  /* ширина scrollbar'a */
  background: transparent;
  /* опционально */
}

body .scrolled-page_content {
  max-height: 83vh;
  overflow-y: scroll;
}

body .equipment-registry_dialog .MuiDialog-paper {
  max-width: 80vw;
  min-width: 60vw;
  height: 70vh;
  background-color: var(--white);
  padding: 0px 35px 35px 35px;
}

body .object_dialog .MuiDialog-paper {
  max-width: 90vw;
  max-height: 90vh;
  background-color: var(--white);
  padding: 35px 35px;
}

body .object_dialog .main-content_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: row-reverse;
}

@media (max-width: 990px) {
  body .object_dialog .main-content_wrapper {
    flex-direction: column;
  }
}

body .employee-modal .MuiDialog-paper {
  padding: 10px 35px;
}

body .form-section-label {
  font-family: var(--INTER);
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: var(--text-color);
}

body .big-title {
  color: var(--black);
  font-size: 24px;
  font-family: var(--INTER);
  font-weight: 500;
  line-height: 32px;
}

body .task_dialog .MuiDialog-paper {
  max-width: 900px;
  height: 90vh;
  background-color: var(--white);
  padding: 35px 36px;
}
body .group_task_dialog .MuiDialog-paper {
  max-width: 90vw;
  height: 70vh;
  background-color: var(--white);
  padding: 35px 36px;
}
body .map_popup {
  background-color: white;
  position: fixed;
  width: 540px;
  top: 80px;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  box-shadow: 0 0 12px rgba(153, 155, 205, 0.27);
  border-radius: 2px 0 0 2px;
  padding: 24px 51px 0 24px;
  box-sizing: border-box;
}

body .map_popup .main-content_wrapper {
  display: block;
}

body .map_popup .button_wrapper,
body .object_dialog .button_wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  justify-content: space-between;
  -moz-column-gap: 10px;
  column-gap: 10px;
}

body .map_popup .pop_up_header,
body .object_dialog .pop_up_header {
  display: inline;
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--popupTitle);
  line-height: 28px;
  color: var(--black);
  margin-top: 45px;
}

body .map_popup .box,
body .object_dialog .box {
  position: relative;
  overflow: hidden;
  width: 294px;
  height: 220px;
  margin-top: 22.4px;
}

body .map_popup .box .img,
body .object_dialog .box .img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  -o-object-fit: cover;
  object-fit: cover;
}

body .map_popup .donut,
body .object_dialog .donut {
  margin-top: 31px;
  display: flex;
  align-items: center;
}

body .map_popup .responsible,
body .object_dialog .responsible {
  margin-bottom: 16px;
  justify-content: space-between;
  border-top: 1px solid #e2e8f0;
}

body .map_popup .responsible_text,
body .object_dialog .responsible_text {
  font-style: normal;
  font-family: var(--INTER);
  font-weight: 400;
  font-size: var(--standart);
  line-height: 24px;
  color: var(--light-black);
}

body .map_popup .data_text,
body .object_dialog .data_text {
  font-style: normal;
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--standartLg);
  line-height: 24px;
  color: var(--black);
  margin: 16px 0;
}

body .map_popup .close_icon,
body .object_dialog .close_icon {
  position: absolute;
  top: 19px;
  right: 22px;
}

body .map_popup .data_small_headers,
body .object_dialog .data_small_headers {
  font-style: normal;
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--standartLg);
  line-height: 20px;
  color: var(--additionalGrey);
  border-bottom: 1px solid #e2e8f0;
}

body .task_dialog-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 50px;
}

body .task_dialog-title {
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--popupTitle);
  color: var(--black);
}

body .task_dialog-sub_title {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

body .task_dialog-sub_title p {
  font-family: var(--INTER);
  font-weight: 400;
  font-size: var(--standartLg);
  color: var(--black);
}

body .task_dialog-sub_title span {
  font-weight: 500;
  font-size: var(--standartLg);
  /* font-size: var(--standartLg); */
}

body .task_dialog-head_buttons {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

body .task_dialog .button_wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  justify-content: space-between;
  -moz-column-gap: 10px;
  column-gap: 10px;
}

body .task_dialog .pop_up_header {
  display: inline;
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--popupTitle);
  line-height: 28px;
  color: var(--black);
  margin-top: 45px;
}

body .task_dialog .box {
  position: relative;
  overflow: hidden;
  width: 294px;
  height: 220px;
  margin-top: 22.4px;
}

body .task_dialog .box .img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  -o-object-fit: cover;
  object-fit: cover;
}

body .task_dialog .donut {
  margin-top: 31px;
  display: flex;
  align-items: center;
}

body .task_dialog .responsible {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
}

body .task_dialog .responsible_text {
  font-style: normal;
  font-family: var(--INTER);
  font-weight: 400;
  font-size: var(--standart);
  line-height: 24px;
  color: var(--light-black);
}

body .task_dialog .data_text {
  font-style: normal;
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--standartLg);
  line-height: 24px;
  color: var(--black);
  margin: 16px 0;
}

body .task_dialog .data_small_headers {
  font-style: normal;
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--standartLg);
  line-height: 20px;
  color: var(--additionalGrey);
  border-bottom: 1px solid #e2e8f0;
}

body .app_header {
  width: calc(100% - var(--widthFullSideBar));
  background: var(--bgWhite);
  border-bottom: 1px solid var(--border);
  box-shadow: none;
}

body .app_header.closed-side-bar {
  width: calc(100% - var(--widthShortSideBar));
}

body .hidden {
  visibility: hidden;
  display: none;
  width: 0;
}

body .template_page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 7px;
  padding-bottom: 25px;
}

body .template_page-header-title {
  font-family: var(--INTER);
  font-size: var(--titlePage);
  font-weight: 500;
  color: var(--black);
  line-height: 48px;
}

@media screen and (max-width: 450px) {
  body .template_page-header-title {
    line-height: 38px;
    margin-bottom: 15px;
  }
}

body .template_page-header-buttons {
  display: flex;
  gap: 20px;
}

body .title-page {
  font-family: var(--INTER);
  font-size: var(--popupTitle);
  font-weight: 500;
  color: var(--mainText);
  line-height: 24px;
  margin-top: 40px;
  margin-bottom:32px
}

@media screen and (max-width: 450px) {
  body .title-page {
    line-height: 38px;
    margin-bottom: 15px;
  }
}

body .subtitle-page {
  font-family: var(--INTER);
  font-size: var(--titleBlock);
  font-weight: 500;
  color: var(--additionalGrey);
}

body .title-block {
  font-family: var(--INTER);
  font-size: var(--titleBlock);
  font-weight: 500;
  color: var(--black);
  line-height: 24px;
}

body .label-block {
  font-family: var(--INTER);
  font-size: var(--standart);
  font-weight: 400;
  color: var(--black);
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

body .label-title {
  font-family: var(--INTER);
  font-size: var(--standartLg);
  font-weight: 600;
  color: var(--black);
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 15px;
}

body .default-label {
  font-family: var(--INTER);
  font-size: var(--standartLg);
  font-weight: 400;
  color: var(--black);
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

body .default-label-wrap {
  font-family: var(--INTER);
  font-size: var(--standartLg);
  font-weight: 400;
  color: var(--black);
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: inherit;
}

body .label-link {
  color: var(--main);
  font-size: var(--standartLg);
  text-decoration: none;
  text-transform: none;
  cursor: pointer;
}

body .btn {
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--standartLg);
  line-height: 16px;
  color: var(--white);
  min-width: 148px;
  text-transform: none;
  border-radius: 32px;
  padding: 16.5px 24px;
}

@media screen and (max-width: 400px) {
  body .btn {
    line-height: 18px;
  }
}

body .btn-cancel {
  background-color: var(--bgGrey);
}

body .btn-cancel:hover {
  background-color: var(--bgBlackGrey);
}

body .btn-outlined-primary {
  color: var(--mainText);
  border: 1px solid var(--main);
  background: var(--outlined);
  font-size: var(--standartLg);
}

body .btn-outlined-primary:hover {
  border: 1px solid var(--main);
  color: var(--main)
/*  box-shadow: 0px 1px 1px rgba(67, 57, 242, 0.11), 0px 0px 0px 4px rgba(67, 57, 242, 0.12); */
}

body .btn-outlined-primary.Mui-disabled {
  background: var(--lightGrey);
  border: 2px solid rgba(100, 116, 139, 0.2);
  color: #a9b4c5;
}

body .btn-outlined-grey {
  color: var(--mainText);
  border: 1px solid var(--main);
  background: var(--outlined);
}

body .btn-outlined-grey:hover {
  color:var(--main)
  /*box-shadow: 0px 1px 1px rgba(119, 131, 150, 0.11), 0px 0px 0px 4px rgba(116, 135, 177, 0.16);*/
}

body .btn-outlined-grey.Mui-disabled {
  background: var(--lightGrey);
  border: 1px solid rgba(100, 116, 139, 0.2);
}

body .btn-primary-fill {
  background-color: var(--main);
  border: 1px solid var(--main);
}

body .btn-primary-fill:hover {
  background-color: var(--main);
  border: 1px solid var(--main);
}

body .btn-primary-fill.Mui-disabled {
  background-color: var(--lightGrey);
  color: var(--lightGreyText);
  border: 1px solid var(--lightGrey);
}

body .btn-red-fill {
  background-color: var(--red);
}

body .image-container {
  position: relative;
  display: inline-block;
}

body .download-button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
  padding: 16px 16px;
  border-radius: 50px;
  background: white;
  color: #fff;
  border: 2px solid var(--main);
  cursor: pointer;
  display: none;
  opacity: 0;
}

body .image-container:hover .download-button {
  display: block;
  transition: opacity 0.3s ease;
  opacity: 1;
  background-color: #e2e8f0;
}
body .btn-red-fill:hover {
  background-color: var(--redHover);
}

body .btn-red-fill.Mui-disabled {
  background-color: var(--redDisabledBg);
  color: var(--redDisabledText);
}

body .btn-upload {
  color: var(--mainText);
  border-color: var(--mainText);
  font-size: var(--standartLg);
  border: 1px solid var(--mainText);
}

body .btn-link,
body .btn-link-border {
  color: var(--main);
  font-size: var(--standartLg);
  text-decoration: none;
  text-transform: none;
}

body .btn-link {
  padding: 0;
}

body .btn-link-border {
  border: 1px solid var(--main);
  padding: 12px 19px;
}

body .btn-remove {
  display: flex;
  gap: 10px;
  color: var(--red);
  border-color: var(--red);
  min-width: 148px;
  border: 1px solid var(--red);
}
body .btn-remove-disabled {
  display: flex;
  gap: 10px;
  color: var(--headers);
  border-color: var(--headers);
  min-width: 148px;
  border: 1px solid var(--headers);
}

body .btn-remove-disabled p {
  margin: 0;
  padding-top: 4px;
}
body .btn-remove p {
  margin: 0;
  padding-top: 4px;
}

body .btn-remove:hover {
  background-color: var(--red);
  border-color: var(--red);
  color: var(--white);
}
body .btn-editPen {
  color: var(--main);
  border-color: var(--main);
  min-width: 148px;
}

body .btn-editPen:hover {
  background-color: var(--main);
  border-color: var(--main);
  color: var(--white);
}
body .btn-basket {
  padding: 5px;
}

body .btn-basket-small {
  padding: 14px 24px;
  min-width: inherit;
}

body .modal .border_bottom {
  border-bottom: 1px solid var(--borderLightGrey);
}

body .modal-title-right {
  display: flex;
  justify-content: flex-end;
}

body .modal-title-between {
  display: flex;
  justify-content: space-between;
}

body .modal-title {
  font-family: var(--INTER);
  font-style: normal;
  font-weight: 500;
  font-size: var(--sectionTitle);
  color: var(--black);
}

body .modal-text {
  font-family: var(--INTER);
  font-size: var(--modalContext);
  font-weight: 500;
  color: var(--black);
  line-height: 32px;
  text-align: center;
  max-width: 420px;
}

body .modal-btn-field {
  padding: 56px 0;
  gap: 15px;
}

body .modal-btn-field-center {
  justify-content: center;
}

body .modal-btn-field-right {
  justify-content: flex-end;
}

body .select_columns .modal-title-between {
  padding-left: 34px;
  padding-top: 32px;
  padding-bottom: 34px;
  padding-right: 34px;
}
.modal-title-between {
  padding-left: 34px !important;
  padding-top: 32px !important;
  padding-bottom: 34px !important;
  padding-right: 34px !important;
}
body .select_columns-form {
  display: flex;
  flex-wrap: wrap;
  padding-top: 14px;
}

body .MuiCheckbox-root {
  padding: 0px;
}

body .select_columns-form .checkbox {
  height: 25px;
}

body .select_columns .select_columns-btns {
  padding-right: 40px;
  padding-left: 40px;
}

@media screen and (max-width: 450px) {
  body .select_columns .select_columns-btns {
    justify-content: center;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 25px;
    gap: 0;
  }
}

@media screen and (max-width: 400px) {
  body .select_columns .select_columns-btns .btn {
    min-width: 131px;
    width: 131px;
  }
}

body .modal_address {
  display: flex;
  align-items: center;
  justify-content: center;
}

body .modal_address-header_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
body .modal_equipdialog-body {
  width: 1000px;
  height: 80vh;
  flex-direction: column;
  justify-content: space-between;
  background: var(--bgWhite);
  box-shadow: 0px 0px 8px rgba(178, 182, 203, 0.32);
  border-radius: 24px;
  padding: 32px 36px;
}
.MuiTreeItem2-iconContainer svg {
  font-size: 20px !important;
}
body .modal_schema-body {
  width: 830px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--bgWhite);
  box-shadow: 0px 0px 8px rgba(178, 182, 203, 0.32);
  border-radius: 12px;
  padding: 26px 36px;
}

body .modal_address-body {
  width: 530px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--bgWhite);
  box-shadow: 0px 0px 8px rgba(178, 182, 203, 0.32);
  border-radius: 12px;
  padding: 26px 36px;
}

body .modal-scroll {
  height: auto;
  max-height: 600px;
  overflow-y: scroll;
}

body .modal_address-body-title {
  font-family: var(--INTER);
  font-style: normal;
  font-weight: 500;
  font-size: var(--sectionTitle);
  color: var(--black);
}

body .modal_address-body-form {
  padding-top: 42px;
}

body .modal_address-body-buttons {
  display: flex;
  justify-content: flex-end;
}

body .MuiTab-root {
  padding: 14px 24px;
  color: var(--headers);
  font-size: 20px;
  line-height: normal;
  font-weight: 500;
  border-radius: 32px;
  text-transform: none; 
}

body .MuiTab-root:not(:last-child) {
  margin-right: 32px;
}

body .MuiTabs-root .Mui-selected {
  background-color: var(--bgListItem);
  color: var(--main);
  border-bottom: none;
}

body .MuiTabs-indicator {
  height: 0px;
}

.MuiButtonBase-root .MuiAccordionSummary-root .Mui-expanded .css-3o3i3q-MuiButtonBase-root-MuiAccordionSummary-root {
  margin-bottom: 24px;
}

.css-at6nep-MuiPaper-root-MuiAccordion-root.Mui-expanded {

}

body .risk-category-container .MuiToggleButton-root {
  padding: 15px 24px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 32px;
  text-transform: none;
  text-wrap: nowrap;
  border: none;
}

body .risk-category-container .MuiToggleButton-root.extremely-high {
  color: var(--error);
}

body .risk-category-container .MuiToggleButton-root.extremely-high.Mui-selected,
body .risk-category-container .MuiToggleButton-root.extremely-high:hover {
  background-color: var(--errorBg);
}

body .risk-category-container .MuiToggleButton-root.high {
  color: var(--orange);
}

body .risk-category-container .MuiToggleButton-root.high.Mui-selected,
body .risk-category-container .MuiToggleButton-root.high:hover {
  background-color: var(--orangeBg);
}

body .risk-category-container .MuiToggleButton-root.significant {
  color: var(--yellow);
}

body .risk-category-container .MuiToggleButton-root.significant.Mui-selected,
body .risk-category-container .MuiToggleButton-root.significant:hover {
  background-color: var(--yellowBg);
}

body .risk-category-container .MuiToggleButton-root.medium {
  color: var(--main);
}

body .risk-category-container .MuiToggleButton-root.medium.Mui-selected,
body .risk-category-container .MuiToggleButton-root.medium:hover {
  background-color: var(--bgListItem);
}

body .risk-category-container .MuiToggleButton-root.moderate {
  color: var(--lilac);
}

body .risk-category-container .MuiToggleButton-root.moderate.Mui-selected,
body .risk-category-container .MuiToggleButton-root.moderate:hover {
  background-color: var(--lilacBg);
}

body .risk-category-container .MuiToggleButton-root.low {
  color: var(--succes);
}

body .risk-category-container .MuiToggleButton-root.low.Mui-selected,
body .risk-category-container .MuiToggleButton-root.low:hover {
  background-color: var(--succesBg);
}

body .small-title {
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--standart);
  line-height: 20px;
  margin-bottom: 8px;
}

body .checkbox {
  height: 56px;
}

body .checkbox-label {
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--standart);
  line-height: 24px;
  color: var(--black);
}

body .switcher-label {
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--standart);
  line-height: 24px;
  color: var(--black);
}

body .switcher label {
  width: 150px;
}

body .input-wrapper .switcher-option .MuiFormControlLabel-label {
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--standart);
  line-height: 24px;
  color: var(--black);
  gap: 8px;
}

body .input-wrapper .date-picker label {
  width: 75%;
}
.font-large{
  color:#fff
}
.font-medium{
  color:#fff
}
body .input-wrapper label,
body .input-wrapper .MuiTypography-root,
body .input-wrapper .MuiInputBase-root {
  font-family: var(--INTER);
  font-weight: 500;
  color: var(--headers);
  font-size: 16px;
  line-height: 20px;
  width: 100%;
}

body .input-wrapper .MuiTypography-root {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

body .input-wrapper .wrap {
  white-space: pre-wrap;
}

body .input-wrapper .error-text {
  font-family: var(--INTER);
  font-weight: 500;
  font-style: normal;
  font-size: var(--small);
  line-height: 15px;
  color: var(--red);
  text-overflow: ellipsis;
  white-space: initial;
  padding-top: 5px;
  width: 200px;
}

body .input-wrapper .switcher {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

body .input-wrapper .switcher-label {
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--standartLg);
  line-height: 24px;
  color: var(--black);
  width: auto;
  margin-right: 20px;
}

body .input-wrapper .switcher label {
  width: 100px;
}

body .input-wrapper .label {
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--standartLg);
  line-height: 24px;
  color: var(--black);
  margin-bottom: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

body .input-wrapper .under-input {
  font-family: var(--INTER);
  font-weight: 400;
  font-size: var(--underInput);
  line-height: 16px;
  color: var(--additionalGrey);
  margin-top: 4px;
  min-height: 32px;
}

body .input-wrapper .sm input::-moz-placeholder {
  color: var(--black);
  font-family: var(--INTER);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1;
}

body .input-wrapper .sm input:-ms-input-placeholder {
  color: var(--black);
  font-family: var(--INTER);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1;
}

body .input-wrapper .sm .MuiTypography-root,
body .input-wrapper .sm .MuiInputLabel-root,
body .input-wrapper .sm input::placeholder,
body .input-wrapper .sm input {
  color: var(--black);
  font-family: var(--INTER);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1;
}

body .input-wrapper .sm input::-moz-placeholder {
  opacity: 1;
}

body .input-wrapper .sm input:-ms-input-placeholder {
  opacity: 1;
}

body .input-wrapper .sm input::placeholder {
  opacity: 1;
}

body .input-wrapper .standart {
  margin-left: 0;
}

body .input-wrapper .standart input::-moz-placeholder {
  color: var(--black);
  font-family: var(--INTER);
  font-size: var(--standart);
  font-weight: 500;
  line-height: 1.1;
}

body .input-wrapper .standart input:-ms-input-placeholder {
  color: var(--black);
  font-family: var(--INTER);
  font-size: var(--standart);
  font-weight: 500;
  line-height: 1.1;
}

body .input-wrapper .standart .MuiTypography-root,
body .input-wrapper .standart .MuiInputLabel-root,
body .input-wrapper .standart input::placeholder,
body .input-wrapper .standart input {
  color: var(--black);
  font-family: var(--INTER);
  font-size: var(--standart);
  font-weight: 500;
  line-height: 1.1;
}

body .input-wrapper .standart input::-moz-placeholder {
  opacity: 1;
}

body .input-wrapper .standart input:-ms-input-placeholder {
  opacity: 1;
}

body .input-wrapper .standart input::placeholder {
  opacity: 1;
}

body .input-wrapper .standart-light input::-moz-placeholder {
  color: var(--black);
  font-family: var(--INTER);
  font-size: var(--standart);
  font-weight: 400;
  line-height: 1.1;
}

body .input-wrapper .standart-light input:-ms-input-placeholder {
  color: var(--black);
  font-family: var(--INTER);
  font-size: var(--standart);
  font-weight: 400;
  line-height: 1.1;
}

body .input-wrapper .standart-light .MuiTypography-root,
body .input-wrapper .standart-light .MuiInputLabel-root,
body .input-wrapper .standart-light input::placeholder,
body .input-wrapper .standart-light input {
  color: var(--black);
  font-family: var(--INTER);
  font-size: var(--standart);
  font-weight: 400;
  line-height: 1.1;
}

body .input-wrapper .standart-light input::-moz-placeholder {
  opacity: 1;
}

body .input-wrapper .standart-light input:-ms-input-placeholder {
  opacity: 1;
}

body .input-wrapper .standart-light input::placeholder {
  opacity: 1;
}

body .input-wrapper .small-light input::-moz-placeholder {
  color: var(--headers);
  font-family: var(--INTER);
  font-size: 16px;
  font-weight: 500;
  line-height: 14px;
  background-color: white !important;
}

body .input-wrapper .small-light input:-ms-input-placeholder {
  color: var(--headers);
  font-family: var(--INTER);
  font-size: 16px;
  font-weight: 500;
  line-height: 14px;
}

body .input-wrapper .small-light .MuiTypography-root,
body .input-wrapper .small-light .MuiInputLabel-root,
body .input-wrapper .small-light input::placeholder,
body .input-wrapper .small-light input {
  color: var(--headers);
  font-family: var(--INTER);
  font-size: 16px;
  font-weight: 500;
  line-height: 17px !important;
}

body .input-wrapper .small-light input::-moz-placeholder {
  opacity: 1;
}

body .input-wrapper .small-light input:-ms-input-placeholder {
  opacity: 1;
}

body .input-wrapper .small-light input::placeholder {
  opacity: 1;
}

body .input-wrapper .small-light .MuiSelect-select {
  margin-left: 6px;
}

body .input-wrapper .select_in_built .MuiInputLabel-root {
  left: 4px;
}

body .input-wrapper .select_in_built .MuiInputLabel-root.Mui-focused {
  display: none;
}

body .input-wrapper .select_in_built .MuiFormLabel-filled.MuiInputLabel-shrink {
  display: none;
  position: absolute;
  transform: translate(14px, -9px) scale(0.75);
}

body
  .input-wrapper
  .select_in_built
  .MuiOutlinedInput-root
  .MuiOutlinedInput-notchedOutline {
  border: none;
}

body .input-wrapper .select_in_built .MuiOutlinedInput-root .MuiSelect-select {
  height: 0;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0;
  color:var(--headers)
}

body .MuiList-padding {
  padding-top: 0;
  padding-bottom: 0;
}

body .input-wrapper .input-block-title {
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--standart);
  line-height: 20px;
  margin-bottom: 5px;
}

body .input-wrapper .input_block_label {
  margin-bottom: 8px;
}

body .input-wrapper .input_block_label-title {
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--standart);
  line-height: 20px;
}

body .input-wrapper .input_block_label-helper {
  font-family: var(--INTER);
  font-weight: 400;
  font-size: var(--underInput);
  color: var(--mainText);
  line-height: 16px;
  text-align: right;
}

body .input-block-field {
  width: 100%;
  margin-bottom: 20px;
}
body .edit-button-image {
  background-size: contain !important;
  color: var(--text-color);
  transition: .2s ease-in;
  cursor: pointer;
}
body .edit-button-image:hover {
  color: var(--accent);
  transition: .2s ease;
}
body .input-block-title {
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--standart);
  line-height: 20px;
  margin-bottom: 8px;
}

body .input-block-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid var(--borderLightGrey);
  padding: 0 16px;
}

body .input-block-wrapper > .input-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

body .MuiInputLabel-root {
  max-width: calc(100% - 55px);
}

body .Mui-focused .MuiOutlinedInput-notchedOutline legend {
  max-width: 70%;
}

body .text_empty_page {
  font-family: var(--INTER);
  font-style: normal;
  font-weight: 400;
  font-size: var(--standart);
  color: var(--additionalGrey);
  margin-top: 7px;
}

.inputText{
  color: #d1d8e0;
  padding: 14.5px 24px;
}
.inputText:focus{
  color: #051d39;
}
.pageNavHeader{
  padding:14px 24px;
  /*margin: 8px 32px 8px 0px;*/
  color:#4D5C6E;
  font-size:20px;
  cursor:pointer
}

.pageNavHeader:hover,
.pageNavHeader:focus{
  color:#1876d2;
  background-color: #eef4fd;
  border-radius: 32px;
}
.pageNavHeaderCureent{
  color:#1876d2;
  background-color: #eef4fd;
  border-radius: 32px;
}

/*# sourceMappingURL=globalstyles.css.map */
