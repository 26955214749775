.header-navbar-text {
    display: flex;
    gap: 64px;
}

.header-navbar-item {
    color: var(--text-color);
    text-transform: inherit;
}

.header-navbar-dropdown {
    display: flex;
}

.header-navbar-dropdown > * {
    border-right: 1px solid var(--border);
    padding: 6px 24px 6px 0;
}

.header-navbar-dropdown > div:last-child {
    border-right: none;
}

.user-name {
    font-size: var(--standartVeryLarge);
    font-family: var(--INTER);
    color: var(--text-color);
}


.default-avatar-icon {
    height: 52px;
    width: 52px;
}

.menu-item {
    padding: 16px !important;
    border-bottom: 1px solid var(--border);
}

.menu-item:hover {
    color: var(--hover);
    background-color: transparent !important;
}

.menu-item:last-child {
    border: none;
}

